import { Component, Mixins } from "vue-property-decorator";

import CardsMixin from "../../common/mixins/cards.mixin";
import CardsTable from "./components/CardsTable/CardsTable.vue";
import BatchCardActions from "./components/BatchCardActions/BatchCardActions.vue";

@Component({
  components: {
    CardsTable,
    BatchCardActions,
    Warnings: () => import("@/components/Warnings/Warnings.vue"),
    IssueCardDialog: () =>
      import(
        "@/features/IssueCard/desktop/components/IssueCardDialog/IssueCardDialog.vue"
      ),
  },
})
export default class DesktopCards extends Mixins(CardsMixin) {
  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
