import { Vue, Component } from "vue-property-decorator";

const components = {
  ChangeCardBalanceForm: () =>
    import(
      "../../../../components/ChangeCardBalanceForm/ChangeCardBalanceForm.vue"
    ),
};

@Component({
  inheritAttrs: false,
  components,
})
export default class ChangeCardBalanceMenu extends Vue {
  private closeOnClick = true;

  private get localListeners() {
    const listeners = { ...this.$listeners };

    delete listeners.success;
    delete listeners.close;

    return listeners;
  }

  private onSucces<T>(value: T, callback: () => void) {
    callback();

    this.$emit("success", value);
  }

  private mounted() {
    components.ChangeCardBalanceForm();
  }
}
