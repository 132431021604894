var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'batch-card-actions d-flex align-center',
    {
      'batch-card-actions_enabled-transitions': _vm.enabledTransitions,
      'batch-card-actions_showed': _vm.selectedCardsCount > 0,
      [_vm.showedClass]: _vm.selectedCardsCount > 0 && _vm.showedClass,
    },
  ]},[_c('p',{staticClass:"blue-grey--text text--darken-4 mb-0 font-weight-600 text-caption-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( _vm.selectedCardsCount === 1 ? "$vuetify.dashboard.selected_count_label_1" : "$vuetify.dashboard.selected_count_label_2", _vm.selectedCardsCount, _vm.declensionOfNouns(_vm.selectedCardsCount, [ _vm.$vuetify.lang.t("$vuetify.info.declension.cards.i_1"), _vm.$vuetify.lang.t("$vuetify.info.declension.cards.i_2"), _vm.$vuetify.lang.t("$vuetify.info.declension.cards.i_3"), ]) ))+" ")]),_c('v-divider',{staticClass:"grey lighten-5 my-auto ml-4 flex-grow-0",style:({
      height: '11px',
      minHeight: '0px',
    }),attrs:{"vertical":""}}),_c('nav',{staticClass:"d-flex"},_vm._l((_vm.actionItems),function(actionItem){return _c('v-tooltip',{key:actionItem.code,attrs:{"open-on-focus":false,"open-on-click":false,"open-on-hover":_vm.$vuetify.breakpoint.lgAndUp,"top":"","close-delay":"150","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"flex-shrink-0"},'div',attrs,false),on),[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(actionItem.enabled),expression:"actionItem.enabled"}],staticClass:"pa-0 ml-4",attrs:{"color":actionItem.color,"text":"","x-small":"","width":"32","height":"32","min-width":"0"},on:{"click":actionItem.onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(actionItem.icon)+" ")])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(actionItem.name)+" ")])}),1),_c('ConfirmDialog',{attrs:{"loading":_vm.confirmActionDialog.loading,"max-width":410},on:{"confirm":_vm.onConfirmActionDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.t(
            `$vuetify.dashboard.card.batch_update.are_you_sure.${_vm.confirmActionDialog.cardAction.toLowerCase()}_cards`,
            _vm.confirmActionDialog.cardsCount,
            _vm.declensionOfNouns(_vm.confirmActionDialog.cardsCount, [
              _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_4'),
              _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_2'),
              _vm.$vuetify.lang.t('$vuetify.info.declension.cards.i_3'),
            ])
          )
        )}})]},proxy:true}]),model:{value:(_vm.confirmActionDialog.showed),callback:function ($$v) {_vm.$set(_vm.confirmActionDialog, "showed", $$v)},expression:"confirmActionDialog.showed"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }