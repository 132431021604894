import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { Card } from "@/types/card";
import { Bank } from "@/types/bank";
import { HtmlElementClassName } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import debounce from "lodash.debounce";

const components = {
  CardActions: () =>
    import("../../../../components/CardActions/CardActions.vue"),
};

@Component({
  components,
})
export default class CardActionsMenu extends Vue {
  @Prop({ type: Object, required: true }) private readonly card!: Card & {
    bank?: Bank;
  };

  private showedMenu = false;

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get htmlElementClassName() {
    return {
      cardActions: HtmlElementClassName.cardActions,
    };
  }

  private mounted() {
    components.CardActions();

    this.$watch(
      () => {
        return (
          this.$productTour.activeIntro.step.alias ===
          StepAlias.MOCK_CARDS_ACTIONS
        );
      },
      debounce((showedMenu) => {
        this.showedMenu = showedMenu;

        if (showedMenu) {
          this.$el.scrollIntoView();
        }
      }, 50),
      {
        immediate: true,
      }
    );
  }
}
