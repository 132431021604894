import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Currency } from "@/types/currency";
import { HtmlElementClassName } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import debounce from "lodash.debounce";

const components = {
  CardSetLimitForm: () =>
    import("../../../../components/CardSetLimitForm/CardSetLimitForm.vue"),
};

@Component({
  inheritAttrs: false,
  components,
})
export default class CardSetLimitMenu extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: String, required: true })
  private readonly cardCurrency!: Currency;
  @Prop({ type: Number, required: true })
  private readonly cardBankId!: number;
  @Prop({ type: Number, required: true })
  private readonly cardId!: number;
  @Prop({ type: Number })
  private readonly cardDailyLimit?: number;
  @Prop({ type: Number })
  private readonly cardDailySpend?: number;
  @Prop({ type: Number })
  private readonly cardTotalLimit?: number;
  @Prop({ type: Number })
  private readonly cardTotalSpend?: number;

  private showedMenu = false;

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get htmlElementClassName() {
    return {
      cardSetLimitButton: HtmlElementClassName.cardSetLimitButton,
      cardSetLimitForm: HtmlElementClassName.cardSetLimitForm,
    };
  }

  private onSuccessSetLimit() {
    this.toggleMenu(false);
    this.$emit("success:set-limit");
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private mounted() {
    components.CardSetLimitForm();

    this.$watch(
      () => {
        return (
          this.$productTour.activeIntro.step.alias ===
          StepAlias.MOCK_CARDS_SET_LIMIT
        );
      },
      debounce((showedMenu) => {
        if (showedMenu) {
          this.$el.scrollIntoView();
        }

        this.showedMenu = showedMenu;
      }, 50),
      {
        immediate: true,
      }
    );
  }
}
