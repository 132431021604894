import { Component, Vue, Prop } from "vue-property-decorator";
import { Currency } from "@/types/currency";
import { HtmlElementClassName } from "@/types/element";

const components = {
  AutoRefillForm: () =>
    import("../../../../components/AutoRefillForm/AutoRefillForm.vue"),
};

@Component({
  inheritAttrs: false,
  components,
})
export default class AutoRefillMenu extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: Number, required: true }) private readonly cardId!: number;
  @Prop({ type: String, required: true })
  private readonly currency!: Currency;
  @Prop({ type: Number })
  private readonly autoRefillAmount?: number;
  @Prop({ type: Number })
  private readonly autoRefillThreshold?: number;
  @Prop({ type: Boolean })
  private readonly autoRefillEnabled?: boolean;

  private showedMenu = false;

  private get htmlElementClassName() {
    return {
      cardsAutoRefillButton: HtmlElementClassName.cardsAutoRefillButton,
    };
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private async onSuccess() {
    this.$emit("success");
    this.toggleMenu(false);
  }

  private mounted() {
    components.AutoRefillForm();
  }
}
