import type { DataTableHeader } from "vuetify/types";
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { Card } from "@/types/card";

import CardMixin from "../../../../common/mixins/card.mixin";

import AutoRefillMenu from "../AutoRefillMenu/AutoRefillMenu.vue";
import CardSetLimitMenu from "../CardSetLimitMenu/CardSetLimitMenu.vue";
import ChangeCardBalanceMenu from "../ChangeCardBalanceMenu/ChangeCardBalanceMenu.vue";
import CardActionsMenu from "../CardActionsMenu/CardActionsMenu.vue";

@Component({
  inheritAttrs: false,
  components: {
    AutoRefillMenu,
    CardSetLimitMenu,
    CardActionsMenu,
    ChangeCardBalanceMenu,
    CardGroup: () => import("../../../../components/CardGroup/CardGroup.vue"),
  },
})
export default class CardsTable extends Mixins(CardMixin) {
  @Prop({ type: Array, required: true }) private readonly items!: Card[];
  @Prop({ type: Number, default: 0 })
  private readonly countCardsWithBatchActions!: number;
  @Prop({ type: Array, required: true })
  private readonly headers!: DataTableHeader[];
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Object, required: true })
  private readonly selectedCards!: Record<number, unknown>;
  @Prop({ type: Number, required: true })
  private readonly selectedCardsCount!: number;
  @PropSync("itemsPerPage", {
    default: 15,
    type: Number,
  })
  private readonly syncedItemsPerPage!: number;

  private editNoteCardId = 0;
  private sortTimeoutId = 0;

  private get selectedAll() {
    return (
      this.selectedCardsCount >= this.countCardsWithBatchActions &&
      this.countCardsWithBatchActions > 0
    );
  }

  private onSelectCard(selected: boolean, item: Card) {
    this.$emit("select:card", selected, item);
  }

  private onSelectAll(selected: boolean) {
    this.$emit("select:all", selected);
  }

  private setEditNoteCardId(id: number) {
    this.editNoteCardId = id;
  }

  private customSort(items: Card[], sortBy: string[], sortDesc: boolean[]) {
    return items.sort((a, b) => {
      for (let index = 0; index < sortBy.length; index++) {
        const sortField = sortBy[index];
        const sortType = sortDesc[index] ? "desc" : "asc";

        switch (sortField) {
          case "cardExpiry": {
            const firstTime = new Date(
              +`20${a.cardExpiryYear}`,
              a.cardExpiryMonth - 1
            ).getTime();

            const secondTime = new Date(
              +`20${b.cardExpiryYear}`,
              b.cardExpiryMonth - 1
            ).getTime();

            const ascVal = firstTime - secondTime;

            if (sortType === "desc") {
              return -ascVal;
            }

            return ascVal;
          }

          case "issued_at": {
            const firstTime = new Date(a.issued_at).getTime();
            const secondTime = new Date(b.issued_at).getTime();

            const ascVal = firstTime - secondTime;

            if (sortType === "desc") {
              return -ascVal;
            }

            return ascVal;
          }

          case "spendAmount": {
            const ascVal = a.spendAmount - b.spendAmount;

            if (sortType === "desc") {
              return -ascVal;
            }

            return ascVal;
          }

          case "balanceAmount": {
            const ascVal = a.balanceAmount - b.balanceAmount;

            if (sortType === "desc") {
              return -ascVal;
            }

            return ascVal;
          }

          default:
            return 0;
        }
      }

      return 0;
    });
  }

  private updateSorting() {
    window.clearTimeout(this.sortTimeoutId);

    this.$emit("update:sorting", true);

    this.sortTimeoutId = window.setTimeout(() => {
      this.$emit("update:sorting", false);
    }, 300);
  }
}
