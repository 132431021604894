import { Vue, Component, Prop } from "vue-property-decorator";
import API from "@api";
import { declensionOfNouns } from "@helpers";
import { Card, CardBatchUpdateAction } from "@/types/card";
import {
  canCloseCard,
  canUnfreezeCard,
  canFreezeCard,
  canUnsuspendCard,
} from "@/lib/card";

@Component
export default class BatchCardActions extends Vue {
  @Prop({ type: Object, required: true })
  private readonly selectedCards!: Record<string, Pick<Card, "id" | "status">>;
  @Prop({ type: Number, required: true })
  private readonly selectedCardsCount!: number;
  @Prop({ type: String }) private readonly showedClass?: string;

  private enabledTransitions = true;

  private confirmActionDialog: {
    loading: boolean;
    showed: boolean;
    cardAction: CardBatchUpdateAction;
    cardsCount: number;
  } = {
    loading: false,
    showed: false,
    cardAction: CardBatchUpdateAction.UNFREEZE,
    cardsCount: 0,
  };

  private get accessibleCardActions() {
    const selectedCards = this.selectedCards;
    const freezeCardIds: number[] = [];
    const unfreezeCardIds: number[] = [];
    const unsuspendCardIds: number[] = [];
    const closeCardIds: number[] = [];

    for (const key in selectedCards) {
      if (Object.prototype.hasOwnProperty.call(selectedCards, key)) {
        const { id, status } = selectedCards[key];

        const accessibleFreezeCard = canFreezeCard({ status });
        const accessibleUnfreezeCard = canUnfreezeCard({ status });
        const accessibleUnsuspendCard = canUnsuspendCard({ status });
        const accessibleCloseCard = canCloseCard({ status });

        if (accessibleFreezeCard) {
          freezeCardIds.push(id);
        }

        if (accessibleUnfreezeCard) {
          unfreezeCardIds.push(id);
        }

        if (accessibleUnsuspendCard) {
          unsuspendCardIds.push(id);
        }

        if (accessibleCloseCard) {
          closeCardIds.push(id);
        }
      }
    }

    return {
      freezeCardIds,
      unfreezeCardIds,
      unsuspendCardIds,
      closeCardIds,
    };
  }

  private get actionItems() {
    return [
      {
        code: "freezeCard",
        icon: "$pause",
        color: "grey",
        name: this.$vuetify.lang.t("$vuetify.dashboard.pause_card"),
        enabled: this.accessibleCardActions.freezeCardIds.length > 0,
        onClick: () => {
          this.seConfirmActionDialog({
            showed: true,
            cardAction: CardBatchUpdateAction.FREEZE,
            cardsCount: this.accessibleCardActions.freezeCardIds.length,
          });
        },
      },
      {
        code: "closeCard",
        icon: "$closeB",
        color: "red",
        name: this.$vuetify.lang.t("$vuetify.dashboard.close_card"),
        enabled: this.accessibleCardActions.closeCardIds.length > 0,
        onClick: () => {
          this.seConfirmActionDialog({
            showed: true,
            cardAction: CardBatchUpdateAction.CLOSE,
            cardsCount: this.accessibleCardActions.closeCardIds.length,
          });
        },
      },
      {
        code: "unfreezeCard",
        icon: "$play",
        color: "grey",
        name: this.$vuetify.lang.t("$vuetify.dashboard.play_card"),
        enabled: this.accessibleCardActions.unfreezeCardIds.length > 0,
        onClick: () => {
          this.seConfirmActionDialog({
            showed: true,
            cardAction: CardBatchUpdateAction.UNFREEZE,
            cardsCount: this.accessibleCardActions.unfreezeCardIds.length,
          });
        },
      },
      {
        code: "unsuspendCard",
        icon: "$fire",
        color: "grey",
        name: this.$vuetify.lang.t("$vuetify.dashboard.unfreeze_card"),
        enabled: this.accessibleCardActions.unsuspendCardIds.length > 0,
        onClick: () => {
          this.seConfirmActionDialog({
            showed: true,
            cardAction: CardBatchUpdateAction.UNSUSPEND,
            cardsCount: this.accessibleCardActions.unsuspendCardIds.length,
          });
        },
      },
    ];
  }

  private declensionOfNouns(count: number, words: string[]) {
    return declensionOfNouns(count, words);
  }

  private seConfirmActionDialog({
    showed,
    cardAction,
    cardsCount,
  }: {
    showed: boolean;
    cardAction?: CardBatchUpdateAction;
    cardsCount?: number;
  }) {
    this.confirmActionDialog.showed = showed;
    this.confirmActionDialog.cardAction =
      cardAction ?? this.confirmActionDialog.cardAction;
    this.confirmActionDialog.cardsCount =
      cardsCount ?? this.confirmActionDialog.cardsCount;
  }

  private async onConfirmActionDialog() {
    const { cardAction } = this.confirmActionDialog;

    let cardIds = this.accessibleCardActions.unfreezeCardIds;

    switch (cardAction) {
      case CardBatchUpdateAction.CLOSE:
        cardIds = this.accessibleCardActions.closeCardIds;
        break;

      case CardBatchUpdateAction.FREEZE:
        cardIds = this.accessibleCardActions.freezeCardIds;
        break;

      case CardBatchUpdateAction.UNSUSPEND:
        cardIds = this.accessibleCardActions.unsuspendCardIds;
        break;

      default:
        cardIds = this.accessibleCardActions.unfreezeCardIds;
        break;
    }

    this.confirmActionDialog.loading = true;

    try {
      await API.card.batchUpdateCard({
        cardAction,
        cardIds,
      });

      this.seConfirmActionDialog({ showed: false });
      this.$emit("update:cards");
    } finally {
      this.confirmActionDialog.loading = false;
    }
  }

  private mounted() {
    const visibleObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        const isSafariBrowser =
          navigator.userAgent.includes("Safari") &&
          !navigator.userAgent.includes("Chrome");

        this.enabledTransitions = isIntersecting || isSafariBrowser;
      },
      {
        threshold: 0,
      }
    );

    visibleObserver.observe(this.$el);

    this.$once("hook:beforeDestroy", () => {
      visibleObserver.disconnect();
    });
  }
}
